import React from 'react';
import {FormattedMessage} from 'react-intl';

import {IconAtom} from '@tabler/icons';

const icons = {
    IconAtom: IconAtom
};

const menuItems = {
    items: [
        {
            id: 'dealerpotal',
            title: <FormattedMessage id="dealerpotal" />,
            type: 'group',
            children: [
                {
                    id: 'home',
                    title: <FormattedMessage id="home" />,
                    type: 'item',
                    url: '/dashboard/default',
                    icon: icons['IconAtom'],
                    breadcrumbs: false
                }
            ]
        }
    ]
};
export default menuItems;
