import React, {useState, useEffect, useCallback, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {CardContent, Grid, Typography, Button} from '@material-ui/core';
import PopupCard from '../../ui-component/cards/PopupCard';
import {gridSpacing} from '../../store/constant';
import axios from 'axios';
import SearchCard from '../../ui-component/cards/SearchCard';
import Swal from 'sweetalert2';
import * as wjcCore from '@grapecity/wijmo';
import { ComBtn } from '../../common/ComBtn';
wjcCore.setLicenseKey(
    'dealerportal.nexentire.com,227244643791918#B0hNLbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPnNjZrUFNvgDMwkjMqdmQWtkbQ5Ua4J5Mzg6bstCNppHW5QGdiVXQxhDRVx4NrIjeRdXe9EmUFVDcEJjQQFjehZmVup4KR5kc4hGe4BTZVRWOrFWWRJ5cIV4d8MWMkFHRmVTOzNlZXF6UzVkNOFUeMp5KXhmd5E4bkNmeixmem3UM7YkZkxmQodkZrtiYRJVOXRUbBtEMo3kbNVkRzF5RQJHUxMjcFZnTVV6T73WQ63SduNHNL3iRwJkUH9mQ4B5dM9kUI3iZId5ZvFHTLNWTQhUQTVjUZdUY6wmcldlV5NlWplXbFZ4KsVGVoV5V0tEbNN5UYRTNMVDewQDU5xENCxWQy54VXhGavZzcxAFOrlWcrNkbINEN8tSbysSdMx6Y6gEUMNmVQFVRRBnQI56Q4lVewFzSFdTNP5USNJjRPtGbSdVWzEkSRJEWi3CZs94b9ZUN9oUViojITJCLiUUOEVkNBFTMiojIIJCL4IjNxUzN4czM0IicfJye#4Xfd5nIJBjMSJiOiMkIsIibvl6cuVGd8VEIgQXZlh6U8VGbGBybtpWaXJiOi8kI1xSfiUTSOFlI0IyQiwiIu3Waz9WZ4hXRgAicldXZpZFdy3GclJFIv5mapdlI0IiTisHL3JyS7gDSiojIDJCLi86bpNnblRHeFBCI73mUpRHb55EIv5mapdlI0IiTisHL3JCNGZDRiojIDJCLi86bpNnblRHeFBCIQFETPBCIv5mapdlI0IiTisHL3JyMDBjQiojIDJCLiUmcvNEIv5mapdlI0IiTisHL3JSV8cTQiojIDJCLi86bpNnblRHeFBCI4JXYoNEbhl6YuFmbpZEIv5mapdlI0IiTis7W0ICZyBlIsISO4ADMxADIzADNwMjMwIjI0ICdyNkIsISbvNmLlJXa49WZ8VmbuwWY4J7bwJXZsFWZkJiOiMXbEJCLiQrlsTbnsD8gtzLhsXKhrLiOiEmTDJCLigTM9ETO7MDN6QDNycjMyIiOiQWSiwSfdtlOicGbmJCLiIjdyIDMyIiOiIXZcJ5L'
);

const UserChgPwPopup = ({chgPop, paraMeter, chgPassPopup, userId, userEmail}) => {
    const loginUser = useSelector((state) => state.userReducer.userData); // 20240604 add HJH
    // console.log("loginUser",loginUser)
    const [disabled, setDisabled] = useState(false);

    //my account focus
    const Focus_ChgPass = useRef([]);
    useEffect(() => {
        Focus_ChgPass.current[0].focus();
        Focus_ChgPass.current[1].focus();
        Focus_ChgPass.current[2].focus();
        Focus_ChgPass.current[3].focus();
        return () => {};
    }, []);

    useEffect(() => {
        if (chgPop === true) {
            Focus_ChgPass.current[0].focus();
            setCurrentPass('');
            setNewPass('');
            setConfirmPass('');
            setCheckNewPass(
                <Typography variant="h6" style={{color: '#f44336'}}>
                    At least 8 characters long with a combination of numbers and letters.
                </Typography>
            );
            setCheckConfirm('');
        }
    }, [chgPop]);
    // enter 포커스 이동 및 처리
    const onKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (e.target.getAttribute('name') === 'currentPass') {
                Focus_ChgPass.current[1].focus();
            }
            if (e.target.getAttribute('name') === 'newPass') {
                Focus_ChgPass.current[2].focus();
            }
            if (e.target.getAttribute('name') === 'confirmPass') {
                Focus_ChgPass.current[3].focus();
            }
            if (e.target.getAttribute('name') === 'chgbtn') {
            }
        }
    };

    //SP_DP_SYS_MY_PASS_CHANGE_P01
    async function SP_DP_SYS_MY_PASS_CHANGE_P01() {
        // const res = await axios.patch(process.env.REACT_APP_REST_SERVER, {
        const res = await axios.patch(process.env.REACT_APP_REST_SERVER, {
	        p_language: loginUser.LANGUAGE,
            p_company: loginUser.COMPANY_CODE,
            p_user: loginUser.USER_ID_REAL,
            p_userip: paraMeter.userip,
            p_pgm: 'SP_DP_SYS_MY_PASS_CHANGE_P01',
            p_option: {
                in_userid: userId,
                in_current_pass: currentPass,
                in_new_pass: newPass,
                in_confirm_pass: confirmPass,
                in_email: emailPass
            }
        });

        if (res.data.rtnResult === 'OK') {
            Swal.fire({
                title: res.data.rtnMessage,
                icon: 'success',
                confirmButtonText: 'OK',
                didClose: () => {
                    setCurrentPass('');
                    setNewPass('');
                    setConfirmPass('');
                    setCheckNewPass(
                        <Typography variant="h6" style={{color: '#f44336'}}>
                            At least 8 characters long with a combination of numbers and letters.
                        </Typography>
                    );
                    setCheckConfirm('');
                }
            });
        } else if (res.data.rtnResult === 'ERR') {
            Swal.fire({
                title: res.data.rtnMessage,
                icon: 'info',
                confirmButtonText: 'OK',
                didClose: () => {}
            });
            if (res.data.rtnMessage === 'Incorrect user password.') {
                Swal.fire({
                    title: res.data.rtnMessage,
                    icon: 'info',
                    confirmButtonText: 'OK',
                    didClose: () => {
                        //ERR 발생 시 userid로 포커스 이동
                        Focus_ChgPass.current[0].focus();
                    }
                });
            }
            if (res.data.rtnMessage === 'Incorrect user password !') {
                Swal.fire({
                    title: res.data.rtnMessage,
                    icon: 'info',
                    confirmButtonText: 'OK',
                    didClose: () => {
                        //ERR 발생 시 userid로 포커스 이동
                        Focus_ChgPass.current[0].focus();
                    }
                });
            }
            if (res.data.rtnMessage === 'Incorrect new password.') {
                Swal.fire({
                    title: res.data.rtnMessage,
                    icon: 'info',
                    confirmButtonText: 'OK',
                    didClose: () => {
                        //ERR 발생 시 userid로 포커스 이동
                        Focus_ChgPass.current[1].focus();
                    }
                });
            }
            if (res.data.rtnMessage === 'Incorrect new password.') {
                Swal.fire({
                    title: res.data.rtnMessage,
                    icon: 'info',
                    confirmButtonText: 'OK',
                    didClose: () => {
                        //ERR 발생 시 userid로 포커스 이동
                        Focus_ChgPass.current[1].focus();
                    }
                });
            }
        }
    }

    const [currentPass, setCurrentPass] = useState('');
    const [checkNewPass, setCheckNewPass] = useState('');
    const [newPass, setNewPass] = useState('');
    const [checkConfirm, setCheckConfirm] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const [emailPass, setEmailPass] = useState('');
    const passCurrent = (e) => {
        setCurrentPass(e.target.value);
    };
    const passNew = useCallback((e) => {
        let regex = new RegExp(/^(?=.*[a-zA-Z])(?=.*[0-9]).{8,25}$/);
        const passNewCurrent = e.target.value;
        setNewPass(passNewCurrent);
        if (!regex.test(passNewCurrent)) {
            setDisabled(true);
            setCheckNewPass(
                <Typography variant="h6" style={{color: '#f44336'}}>
                    At least 8 characters long with a combination of numbers and letters.
                </Typography>
            );
        } else {
            setCheckNewPass(
                <Typography variant="h6" style={{color: '#03C75A'}}>
                    OK.
                </Typography>
            );
        }
    }, []);

    const passconfirm = useCallback(
        (e) => {
            let regex = new RegExp(/^(?=.*[a-zA-Z])(?=.*[0-9]).{8,25}$/);
            const passconfrimCurrent = e.target.value;
            setConfirmPass(passconfrimCurrent);
            if (!regex.test(passconfrimCurrent)) {
                setDisabled(true);
                setCheckConfirm(
                    <Typography variant="h6" style={{color: '#f44336'}}>
                        Passwords did not match. Try again.
                    </Typography>
                );
            } else {
                setDisabled(false);
                setCheckConfirm(
                    <Typography variant="h6" style={{color: '#03C75A'}}>
                        OK
                    </Typography>
                );
            }
        },
        [newPass]
    );
    const passEmail = useCallback((e) => {
        let regex = new RegExp(
            "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
        );
        const emailCurrent = e.target.value;
        setEmailPass(emailCurrent);

        if (!regex.test(emailCurrent)) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, []);

    const clickChgData = () => {
        SP_DP_SYS_MY_PASS_CHANGE_P01();
    };

    useEffect(() => {
        if (checkNewPass === '') {
            setCheckNewPass(
                <Typography variant="h6" style={{color: '#f44336'}}>
                    At least 8 characters long with a combination of numbers and letters.
                </Typography>
            );
        }

        return () => {};
    }, [checkNewPass]);

    useEffect(() => {
        setEmailPass(userEmail);
        return () => {};
    }, [userEmail]);

        // submit 후 새로고침 방지
        const noSubmit = (e) => {
            e.preventDefault();
          };

    return (
        <Grid container spacing={gridSpacing}>
            <div className="chgpass">
                <Grid item xs={12}>
                    <PopupCard>
                        <SearchCard>
                            <Typography variant="h4">Change Password</Typography>
                        </SearchCard>
                        <div className="content">
                            <CardContent>
                                <PopupCard>
                                    <div className="row row-cols-2 mb-4">
                                        <div className="col-4">
                                            <div className="input-group input-group-sm justify-content-center">
                                                <span className="label-span input-group-text col-4" id="label-span">
                                                    <Typography variant="h5">User ID</Typography>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-7">
                                            <div className="input-group input-group-sm">
                                                <input
                                                    className="form-control ms-3"
                                                    type="text"
                                                    defaultValue={userId}
                                                    name="id"
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row row-cols-2 mb-4">
                                        <div className="col-4">
                                            <div className="input-group input-group-sm justify-content-center">
                                                <span className="label-span input-group-text col-4" id="label-span">
                                                    <Typography variant="h5">Current Password</Typography>
                                                </span>
                                            </div>
                                        </div>
                                        <form className="col-7" onSubmit={noSubmit}>
                                            <div className="input-group input-group-sm">
                                                <input
                                                    className="form-control ms-3"
                                                    type="password"
                                                    name="currentPass"
                                                    onChange={passCurrent}
                                                    value={currentPass || ''}
                                                    ref={(el) => (Focus_ChgPass.current[0] = el)}
                                                    onFocus={(e) => e.target.select()}
                                                    onKeyPress={onKeyPress}
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </form>
                                    </div>
                                    <div className="row row-cols-2">
                                        <div className="col-4">
                                            <div className="input-group input-group-sm justify-content-center">
                                                <span className="label-span input-group-text col-4" id="label-span">
                                                    <Typography variant="h5">New Password</Typography>
                                                </span>
                                            </div>
                                        </div>
                                        <form className="col-7" onSubmit={noSubmit}>
                                            <div className="input-group input-group-sm">
                                                <input
                                                    className="form-control ms-3"
                                                    type="password"
                                                    name="newPass"
                                                    onChange={passNew}
                                                    value={newPass || ''}
                                                    ref={(el) => (Focus_ChgPass.current[1] = el)}
                                                    onFocus={(e) => e.target.select()}
                                                    onKeyPress={onKeyPress}
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </form>
                                        <div className="col-4"></div>
                                        <div className="col-7  mb-1">
                                            <div className="input-group input-group-sm ms-3">{checkNewPass}</div>
                                        </div>
                                    </div>
                                    <div className="row row-cols-2 mb-1">
                                        <div className="col-4">
                                            <div className="input-group input-group-sm justify-content-center">
                                                <span className="label-span input-group-text col-4" id="label-span">
                                                    <Typography variant="h5">Confirm Password</Typography>
                                                </span>
                                            </div>
                                        </div>
                                        <form className="col-7" onSubmit={noSubmit}>
                                            <div className="input-group input-group-sm">
                                                <input
                                                    className="form-control ms-3"
                                                    type="password"
                                                    name="confirmPass"
                                                    onChange={passconfirm}
                                                    value={confirmPass || ''}
                                                    ref={(el) => (Focus_ChgPass.current[2] = el)}
                                                    onFocus={(e) => e.target.select()}
                                                    onKeyPress={onKeyPress}
                                                    aria-label=""
                                                    aria-describedby="basic-addon1"
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </form>
                                        <div className="col-4"></div>
                                        <div className="col-7  mb-1">
                                            <div className="input-group input-group-sm ms-3">
                                                <span style={{color: 'white'}}>.</span>
                                                {checkConfirm}
                                            </div>
                                        </div>
                                    </div>
                                </PopupCard>
                                <div className="row row-cols-2 justify-content-end mt-2">
                                    <div className="col-5">
                                        <div className="input-group input-group-sm">
                                            <ComBtn 
                                                pName={"chgbtn"} 
                                                pBtnName={"Change Password"} 
                                                pClassName={"comBtn col-12"} 
                                                pOnClick={clickChgData} 
                                                pRef={(el) => (Focus_ChgPass.current[3] = el)} 
                                                pOnKeyPress={onKeyPress} 
                                            />
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="input-group input-group-sm">
                                            <Button className="col-12" onClick={chgPassPopup} size="small" variant="contained">
                                                <Typography variant="h6">Cancel</Typography>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </div>
                    </PopupCard>
                </Grid>
            </div>
        </Grid>
    );
};

export default UserChgPwPopup;
