import React, {useState, useEffect} from 'react';
import {Link} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';

import config from './../../../config';

import logo from './../../../assets/images/NEXEN_TIRE_LOGO.png';

const LogoSection = () => {
    const [logoPath, setlogoPath] = useState('');
    useEffect(() => {
        if (sessionStorage.getItem('userCompanyData') == 'CA10') {
            setlogoPath(config.caPath);
        } else if (sessionStorage.getItem('userCompanyData') == 'AU10') {
            setlogoPath(config.auPath);
        }
        return () => {};
    }, [logoPath]);

    return (
        <React.Fragment>
            <Link component={RouterLink} to={logoPath} style={{margin: '3px'}}>
                <img src={logo} alt="Nexen" width="170" />
            </Link>
        </React.Fragment>
    );
};

export default LogoSection;
