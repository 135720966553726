import axios from 'axios';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import {IconAtom} from '@tabler/icons';

const icons = {
    IconAtom: IconAtom
};

export const getTopMenuList = async ({}) => {
    const url = '/api/menu';
    const res = await axios
        .patch(process.env.REACT_APP_REST_SERVER, {
            p_language: sessionStorage.getItem('userLanguage'),
            p_company: sessionStorage.getItem('userCompanyData'),
            p_user: sessionStorage.getItem('userID'),
            p_userip: '1.1.1.1',
            p_pgm: 'SP_DP_COM_TOPMENU',
            p_option: {}
        })
        .catch((e) => console.log(e));
    const result = res.data.rows;

    const menuItems = {
        items: [
            {
                title: <FormattedMessage id="dealerpotal" />,
                type: 'group',
                children: [
                    {
                        id: 'home',
                        title: <FormattedMessage id="home" />,
                        type: 'item',
                        url: '/dashboard/default',
                        icon: icons['IconAtom'],
                        breadcrumbs: false
                    }
                ]
            }
        ]
    };

    const itemList = result.filter((item) => item.MENU_TYPE === 'item');

    let resultList = [];
    itemList.forEach((item) => {
        const obj = {
            id: item.MENU_ID,
            title: item.MENU_NAME,
            type: 'item',
            icon: icons[item.ICON_NAME],
            url: item.LINK_URL,
            sort: parseInt(item.SORT_ORDER)
        };
        resultList.push(obj);
    });
    resultList.sort((a, b) => {
        return a.sort - b.sort;
    });

    return resultList;
};
