import * as actionTypes from '../actions';

export const initialState = {
    ordNo: [],
    soldTo: [],
    shipTo: [],
    itemCode: [],
    itemQty: [],
    itemPrice: [],
    custPo: '',
    pickUp: [],
    setOder: [],
    setCartInfo: []
};

const orderCart = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ORDER_ORDNO:
            return {...state, ordNo: action.ordNo};
        case actionTypes.SET_ORDER_SOLDTO:
            return {...state, soldTo: action.soldTo};
        case actionTypes.SET_ORDER_SHIPTO:
            return {...state, shipTo: action.shipTo};
        case actionTypes.SET_ORDER_ITEMCODE:
            return {...state, itemCode: action.itemCode};
        case actionTypes.SET_ORDER_ITEMQTY:
            return {...state, itemQty: action.itemQty};
        case actionTypes.SET_ORDER_ITEMPRICE:
            return {...state, itemPrice: action.itemPrice};
        case actionTypes.SET_ORDER_CUSTPO:
            return {...state, custPo: action.custPo};
        case actionTypes.SET_ORDER_PICKUP:
            return {...state, pickUp: action.pickUp};
        case actionTypes.SET_ORDER:
            return {
                ...state,
                // ordNo: action.ordNo,
                // soldTo: action.soldTo,
                // shipTo: action.shipTo,
                // itemCode: action.itemCode,
                // itemQty: action.itemQty,
                // itemPrice: action.itemPrice,
                // cuRrency: action.cuRrency,
                // custPo: action.custPo,
                // pickUp: action.pickUp
                setOder: action.setOder
            };
        case actionTypes.SET_CART_INFO:
            return {
                ...state,
                setCartInfo: action.setCartInfo
            };
        default:
            return state;
    }
};

export default orderCart;
