export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_INITIALISE = 'ACCOUNT_INITIALISE';
export const FIREBASE_STATE_CHANGED = 'FIREBASE_STATE_CHANGED';
export const SET_MENU = 'SET_MENU';
export const SET_USER = 'SET_USER';
export const SET_ACCOUNT_RTNRESULT = 'SET_ACCOUNT_RTNRESULT';

// Action for Combine Reducer
export const MENU_OPEN = '@customization/MENU_OPEN';
export const MENU_TYPE = '@customization/MENU_TYPE';
export const THEME_LOCALE = '@customization/THEME_LOCALE';
export const THEME_RTL = '@customization/THEME_RTL';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const SNACKBAR_OPEN = '@snackbar/SNACKBAR_OPEN';

// Order Action
export const SET_ORDER_ORDNO = 'SET_ORDER_ORDNO';
export const SET_ORDER_SOLDTO = 'SET_ORDER_SOLDTO';
export const SET_ORDER_SHIPTO = 'SET_ORDER_SHIPTO';
export const SET_ORDER_ITEMCODE = 'SET_ORDER_ITEMCODE';
export const SET_ORDER_ITEMQTY = 'SET_ORDER_ITEMQTY';
export const SET_ORDER_ITEMPRICE = 'SET_ORDER_ITEMPRICE';
export const SET_ORDER_CUSTPO = 'SET_ORDER_CUSTPO';
export const SET_ORDER_PICKUP = 'SET_ORDER_PICKUP';
export const SET_CART_INFO = 'SET_CART_INFO';
export const SET_ORDER = 'SET_ORDER';

// export const SET_SOLDTO = 'SET_SOLDTO';
// export const SET_SHIPTO = 'SET_SHIPTO';
// export const SET_ITEMCODE = 'SET_ITEMCODE';
// export const SET_PICKUP = 'SET_PICKUP';
