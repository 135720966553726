//borderRadius 고정
const config = {
    basename: '/dp',
    // defaultPath: '/dashboard/default',
    defaultPath: '/pages/login',
    homePath: '/dashboard/default',
    caPath: '/OrderSheet/ordVendorExcel',
    auPath: '/Cart/cartOrder',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 4,
    theme: 'light',
    i18n: 'en' // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
};

export default config;
