import {combineReducers} from 'redux';
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import customizationReducer from './customizationReducer';
import snackbarReducer from './snackbarReducer';
import parameterReducer from './ParaMeter/parameterReducer';
import userReducer from './UserData/userReducer';
import orderCartReducer from './CustomerNo/orderCartReducer';
import accountReducer from './accountReducer';

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["userReducer"],
  };

const reducer = combineReducers({
    customization: customizationReducer,
    snackbar: snackbarReducer,
    parameter: parameterReducer,
    userReducer: userReducer,
    orderCart: orderCartReducer,
    accountReducer:accountReducer
});

export default persistReducer(persistConfig, reducer);
