import React, {lazy} from 'react';
import {Route, Switch, useLocation} from 'react-router-dom';
import MinimalLayout from './../layout/MinimalLayout';

// login
const AuthLogin = lazy(() => import('../views/pages/authentication/login/caLogin/caLogin'));
const AuLogin = lazy(() => import('../views/pages/authentication/login/auLogin/auLogin'));

const AuthenticationRoutes = () => {
    const location = useLocation();

    return (
        <Route path={['/pages/login', '/AU/login']}>
            <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                    <Route path="/pages/login" component={AuthLogin} />
                    <Route path="/AU/login" component={AuLogin} />
                </Switch>
            </MinimalLayout>
        </Route>
    );
};

export default AuthenticationRoutes;
