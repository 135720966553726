export const gridSpacing = 0.5; //card 간격
export const drawerWidth = 260; // 메뉴 넓이
export const gridStyle = [{title: '30'}, {title: '50'}, {title: '100'}]; // 그리드 로우 서치 개수

//현재 시간
let date = new Date();
let year = date.getFullYear();
let month = ('0' + (1 + date.getMonth())).slice(-2);
let day = ('0' + date.getDate()).slice(-2);
export const timeNow = year + month + day;
