import * as actionTypes from "../actions";

export const initialState = {
    userData: [],
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_USER:
            return {
                ...state, 
                userData: action.userData
            };
        default:
            return state;
    }
};

export default userReducer;
