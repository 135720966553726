import React, {useState, useEffect, useRef, useCallback} from 'react';
import {CardContent, Divider, Grid, Typography, Button} from '@material-ui/core';
import PopupCard from '../../ui-component/cards/PopupCard';
import {gridSpacing} from '../../store/constant';
import SearchCard from '../../ui-component/cards/SearchCard';
import {useSelector, useDispatch} from 'react-redux';
// import {setUserData} from '../../store/UserData/userAction';
import axios from 'axios';
import Swal from 'sweetalert2';
import UserChgPwPopup from './UserChgPwPopup';
import * as wjcCore from '@grapecity/wijmo';
import { ComBtn } from '../../common/ComBtn';
wjcCore.setLicenseKey(
    'dealerportal.nexentire.com,227244643791918#B0hNLbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPnNjZrUFNvgDMwkjMqdmQWtkbQ5Ua4J5Mzg6bstCNppHW5QGdiVXQxhDRVx4NrIjeRdXe9EmUFVDcEJjQQFjehZmVup4KR5kc4hGe4BTZVRWOrFWWRJ5cIV4d8MWMkFHRmVTOzNlZXF6UzVkNOFUeMp5KXhmd5E4bkNmeixmem3UM7YkZkxmQodkZrtiYRJVOXRUbBtEMo3kbNVkRzF5RQJHUxMjcFZnTVV6T73WQ63SduNHNL3iRwJkUH9mQ4B5dM9kUI3iZId5ZvFHTLNWTQhUQTVjUZdUY6wmcldlV5NlWplXbFZ4KsVGVoV5V0tEbNN5UYRTNMVDewQDU5xENCxWQy54VXhGavZzcxAFOrlWcrNkbINEN8tSbysSdMx6Y6gEUMNmVQFVRRBnQI56Q4lVewFzSFdTNP5USNJjRPtGbSdVWzEkSRJEWi3CZs94b9ZUN9oUViojITJCLiUUOEVkNBFTMiojIIJCL4IjNxUzN4czM0IicfJye#4Xfd5nIJBjMSJiOiMkIsIibvl6cuVGd8VEIgQXZlh6U8VGbGBybtpWaXJiOi8kI1xSfiUTSOFlI0IyQiwiIu3Waz9WZ4hXRgAicldXZpZFdy3GclJFIv5mapdlI0IiTisHL3JyS7gDSiojIDJCLi86bpNnblRHeFBCI73mUpRHb55EIv5mapdlI0IiTisHL3JCNGZDRiojIDJCLi86bpNnblRHeFBCIQFETPBCIv5mapdlI0IiTisHL3JyMDBjQiojIDJCLiUmcvNEIv5mapdlI0IiTisHL3JSV8cTQiojIDJCLi86bpNnblRHeFBCI4JXYoNEbhl6YuFmbpZEIv5mapdlI0IiTis7W0ICZyBlIsISO4ADMxADIzADNwMjMwIjI0ICdyNkIsISbvNmLlJXa49WZ8VmbuwWY4J7bwJXZsFWZkJiOiMXbEJCLiQrlsTbnsD8gtzLhsXKhrLiOiEmTDJCLigTM9ETO7MDN6QDNycjMyIiOiQWSiwSfdtlOicGbmJCLiIjdyIDMyIiOiIXZcJ5L'
);

// const userProps = (dispatch) => {
//     return {
//         setUserData: (user) => dispatch(setUserData(user))
//     };
// };

const UserMyAccountPopup = ({myAccPop, myAccPopup}) => {
    const dispatch = useDispatch();
    const paraMeter = useSelector((state) => state.parameter);
    const loginUser = useSelector((state) => state.userReducer.userData); // 20240604 add HJH
    // console.log("loginUser",loginUser)
    const [disabled, setDisabled] = useState(false);
    //my account focus
    const Focus_MyAccount = useRef([]);

    useEffect(() => {
        Focus_MyAccount.current[0].focus();
        Focus_MyAccount.current[1].focus();
        Focus_MyAccount.current[2].focus();
        Focus_MyAccount.current[3].focus();
        Focus_MyAccount.current[4].focus();
    }, []);

    useEffect(() => {
        if (myAccPopup === true) {
            SP_DP_SYS_MY_ACCOUNT_S01();
            setEmailCheck('');
            setDisabled(false);
        }
    }, [myAccPopup]);

    // enter 포커스 이동 및 처리
    const onKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (e.target.getAttribute('name') === 'userFirstName') {
                Focus_MyAccount.current[1].focus();
            }
            if (e.target.getAttribute('name') === 'userLastName') {
                Focus_MyAccount.current[2].focus();
            }
            if (e.target.getAttribute('name') === 'email') {
                Focus_MyAccount.current[3].focus();
            }
            if (e.target.getAttribute('name') === 'userPhone') {
                Focus_MyAccount.current[4].focus();
            }
            if (e.target.getAttribute('name') === 'savebtn') {
            }
        }
    };

    const [myAccountData, setMyAccountData] = useState([]);

    async function SP_DP_SYS_MY_ACCOUNT_S01() {
        const res = await axios.patch(process.env.REACT_APP_REST_SERVER, {
            p_language: loginUser.LANGUAGE,
            p_company: loginUser.COMPANY_CODE,
            p_user: loginUser.USER_ID_REAL,
            p_userip: paraMeter.userip,
            p_pgm: 'SP_DP_SYS_MY_ACCOUNT_S01',
            p_option: {
                in_userid: loginUser.USER_ID_REAL,
            }
        });
        setMyAccountData(res.data.rows);
    }

    async function SP_DP_SYS_MY_ACCOUNT_P01() {
        const res = await axios.patch(process.env.REACT_APP_REST_SERVER, {
            p_language: loginUser.LANGUAGE,
            p_company: loginUser.COMPANY_CODE,
            p_user: loginUser.USER_ID_REAL,
            p_userip: paraMeter.userip,
            p_pgm: 'SP_DP_SYS_MY_ACCOUNT_P01',
            p_option: {
                in_userid: userId,
                in_first_name: userFirstName,
                in_last_name: userLastName,
                in_name: userName,
                in_email: userEmail,
                in_phone: userPhone
            }
        });
        dispatch({type: 'SET_ACCOUNT_RTNRESULT', accountRtnResult: res.data.rtnResult});
        if (res.data.rtnResult === 'OK') {
            Swal.fire({
                title: res.data.rtnMessage,
                icon: 'success',
                confirmButtonText: 'OK',
                didClose: () => {
                    sessionStorage.setItem('userFirstName', userFirstName);
                    sessionStorage.setItem('userLastName', userLastName);
                    sessionStorage.setItem('userEmail', userEmail);
                    myAccPop();
                }
            });
        } else if (res.data.rtnResult === 'ERR') {
            if (res.data.rtnMessage === 'Missing First Name') {
                Swal.fire({
                    title: res.data.rtnMessage,
                    icon: 'info',
                    confirmButtonText: 'OK',
                    didClose: () => {
                        //ERR 발생 시 해당 포커스로 집중
                        Focus_MyAccount.current[0].focus();
                    }
                });
            }
            if (res.data.rtnMessage === 'Missing Last Name') {
                Swal.fire({
                    title: res.data.rtnMessage,
                    icon: 'info',
                    confirmButtonText: 'OK',
                    didClose: () => {
                        //ERR 발생 시 해당 포커스로 집중
                        Focus_MyAccount.current[1].focus();
                    }
                });
            }
            if (res.data.rtnMessage === 'Invalid Email Format') {
                Swal.fire({
                    title: res.data.rtnMessage,
                    icon: 'info',
                    confirmButtonText: 'OK',
                    didClose: () => {
                        //ERR 발생 시 해당 포커스로 집중
                        Focus_MyAccount.current[2].focus();
                    }
                });
            }
            if (res.data.rtnMessage === 'Unique constraint : Email (already exit)') {
                Swal.fire({
                    title: res.data.rtnMessage,
                    icon: 'info',
                    confirmButtonText: 'OK',
                    didClose: () => {
                        //ERR 발생 시 해당 포커스로 집중
                        Focus_MyAccount.current[2].focus();
                    }
                });
            }
            if (res.data.rtnMessage === 'Incorrect Phone number !') {
                Swal.fire({
                    title: res.data.rtnMessage,
                    icon: 'info',
                    confirmButtonText: 'OK',
                    didClose: () => {
                        //ERR 발생 시 해당 포커스로 집중
                        Focus_MyAccount.current[3].focus();
                    }
                });
            }
        }
    }

    const [chgPop, setChgPop] = useState(false);
    const chgPassPopup = () => {
        setChgPop((isopen) => !isopen);
    };

    const [userId, setUserId] = useState('');
    const [userName, setUserName] = useState('');
    const [userFirstName, setUserFirstName] = useState('');
    const [userLastName, setUserLastName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [emailCheck, setEmailCheck] = useState('');
    const [userPhone, setUserPhone] = useState('');

    const onUserName = (e) => {
        setUserName(e.target.value);
    };
    const onUserFirstName = (e) => {
        setUserFirstName(e.target.value);
    };
    const onUserLastName = (e) => {
        setUserLastName(e.target.value);
    };
    const onUserEmail = useCallback((e) => {
        let regex = new RegExp(
            "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
        );
        const emailCurrent = e.target.value;
        setUserEmail(emailCurrent);

        if (!regex.test(emailCurrent)) {
            setDisabled(true);
            setEmailCheck(
                <Typography variant="h6" style={{color: '#f44336'}}>
                    Invalid Email Format.
                </Typography>
            );
        } else {
            setDisabled(false);
            setEmailCheck(
                <Typography variant="h6" style={{color: '#03C75A'}}>
                    OK.
                </Typography>
            );
        }
    }, []);
    const onUserPhone = (e) => {
        setUserPhone(e.target.value);
    };
    useEffect(() => {
        if (myAccountData.length) {
            setUserId(myAccountData[0].USER_ID);
            setUserName(myAccountData[0].USER_NAME);
            setUserFirstName(myAccountData[0].FIRST_NAME);
            setUserLastName(myAccountData[0].LAST_NAME);
            setUserEmail(myAccountData[0].EMAIL);
            setUserPhone(myAccountData[0].TELEPHONE);
        }
        return () => {};
    }, [myAccountData]);

    const myAccountSave = () => {
        SP_DP_SYS_MY_ACCOUNT_P01();
    };

    return (
        <Grid container spacing={gridSpacing}>
            <div className="myaccount">
                <Grid item xs={12}>
                    <PopupCard>
                        <Typography variant="h3">Dealer Portal System</Typography>
                        <Typography variant="h2">My Account</Typography>
                        <Divider className="mb-2" />
                        <div className="content">
                            <CardContent>
                                <SearchCard>
                                    <Typography variant="h4">Personal Information</Typography>
                                </SearchCard>
                                <div className="mt-2">
                                    <PopupCard>
                                        <div className="row row-cols-2 mb-3">
                                            <div className="col-3">
                                                <div className="input-group input-group-sm">
                                                    <span className="label-span input-group-text col-4" id="label-span">
                                                        <Typography variant="h5">User ID</Typography>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-8">
                                                <div className="input-group input-group-sm">
                                                    <input
                                                        name='id'
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue={userId}
                                                        disabled
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row row-cols-2 mb-3">
                                            <div className="col-3">
                                                <div className="input-group input-group-sm">
                                                    <span className="label-span input-group-text col-4" id="label-span">
                                                        <Typography variant="h5">Company Name</Typography>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-8">
                                                <div className="input-group input-group-sm">
                                                    <input
                                                        name="userName"
                                                        value={userName || ''}
                                                        onChange={onUserName}
                                                        onKeyPress={onKeyPress}
                                                        type="text"
                                                        className="form-control"
                                                        onFocus={(e) => e.target.select()}
                                                        autoComplete="off"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row row-cols-2 mb-4 mt-5">
                                            <div className="col-3">
                                                <div className="input-group input-group-sm">
                                                    <span className="label-span input-group-text col-4" id="label-span">
                                                        <Typography variant="h5">
                                                            First Name<span style={{color: 'red'}}>*</span>
                                                        </Typography>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-8">
                                                <div className="input-group input-group-sm">
                                                    <input
                                                        name="userFirstName"
                                                        value={userFirstName || ''}
                                                        onChange={onUserFirstName}
                                                        onKeyPress={onKeyPress}
                                                        type="text"
                                                        className="form-control"
                                                        onFocus={(e) => e.target.select()}
                                                        ref={(el) => (Focus_MyAccount.current[0] = el)}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row row-cols-2 mb-4">
                                            <div className="col-3">
                                                <div className="input-group input-group-sm">
                                                    <span className="label-span input-group-text col-4" id="label-span">
                                                        <Typography variant="h5">
                                                            Last Name<span style={{color: 'red'}}>*</span>
                                                        </Typography>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-8">
                                                <div className="input-group input-group-sm">
                                                    <input
                                                        name="userLastName"
                                                        value={userLastName || ''}
                                                        onChange={onUserLastName}
                                                        onKeyPress={onKeyPress}
                                                        type="text"
                                                        className="form-control"
                                                        onFocus={(e) => e.target.select()}
                                                        ref={(el) => (Focus_MyAccount.current[1] = el)}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row row-cols-2">
                                            <div className="col-3">
                                                <div className="input-group input-group-sm">
                                                    <span className="label-span input-group-text col-4" id="label-span">
                                                        <Typography variant="h5">
                                                            Email Address<span style={{color: 'red'}}>*</span>
                                                        </Typography>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-8">
                                                <div className="input-group input-group-sm">
                                                    <input
                                                        name="email"
                                                        value={userEmail || ''}
                                                        onChange={onUserEmail}
                                                        onKeyPress={onKeyPress}
                                                        type="text"
                                                        className="form-control"
                                                        onFocus={(e) => e.target.select()}
                                                        ref={(el) => (Focus_MyAccount.current[2] = el)}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                                <div className="col-3"></div>
                                                <div className="col-8" style={{display: 'flex'}}>
                                                    <span style={{color: 'white'}}>.</span>
                                                    {emailCheck}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row row-cols-2 mb-3">
                                            <div className="col-3">
                                                <div className="input-group input-group-sm">
                                                    <span className="label-span input-group-text col-4" id="label-span">
                                                        <Typography variant="h5">
                                                            Telephone<span style={{color: 'red'}}>*</span>
                                                        </Typography>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-8">
                                                <div className="input-group input-group-sm">
                                                    <input
                                                        name="userPhone"
                                                        value={userPhone || ''}
                                                        onChange={onUserPhone}
                                                        onKeyPress={onKeyPress}
                                                        type="text"
                                                        className="form-control"
                                                        onFocus={(e) => e.target.select()}
                                                        ref={(el) => (Focus_MyAccount.current[3] = el)}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </PopupCard>
                                </div>
                                <div className="row justify-content-end mt-2">
                                    <ComBtn 
                                        pName={"savebtn"} 
                                        pBtnName={"Save"} 
                                        pClassName={"comBtn col-2 me-3"} 
                                        pOnClick={myAccountSave} 
                                        pRef={(el) => (Focus_MyAccount.current[4] = el)} 
                                        pOnKeyPress={onKeyPress}
                                        pDisabled={disabled} 
                                    />
                                    <Button onClick={chgPassPopup} className="col-3 me-3" size="small" variant="contained">
                                        <Typography variant="h6">Change Password</Typography>
                                    </Button>
                                    <Button onClick={myAccPop} className="col-2 me-3" size="small" variant="contained">
                                        <Typography variant="h6">Close</Typography>
                                    </Button>
                                </div>
                            </CardContent>
                        </div>
                    </PopupCard>
                </Grid>
            </div>
            <div className={chgPop ? 'chgpass-show' : 'chgpass-hide'}>
                <UserChgPwPopup chgPop={chgPop} paraMeter={paraMeter} chgPassPopup={chgPassPopup} userId={userId} userEmail={userEmail} />
            </div>
        </Grid>
    );
};

// export default connect(null, userProps)(UserMyAccountPopup);
export default UserMyAccountPopup;
