export const usercompany = (usercompany) => ({type: 'SET_USERCOMPANYDATA', payload: usercompany});
export const userid = (userid) => ({type: 'SET_USERIDDATA', payload: userid});

export const initialState = {
    language: 'EN',
    usercompany: sessionStorage.getItem('userCompanyData'),
    userid: sessionStorage.getItem('userID'),
    userip: '1.1.1.1'
};

const paraMeter = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_USERCOMPANYDATA':
            return {...state, usercompany: action.payload};
        case 'SET_USERIDDATA':
            return {...state, userid: action.payload};
        default:
            return state;
    }
};

export default paraMeter;
