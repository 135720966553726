import React, {useState, useEffect, useRef} from 'react';
import {useMediaQuery, Hidden, makeStyles, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {IconLogout} from '@tabler/icons';
import {useDispatch} from 'react-redux';
import * as actionTypes from '../../../store/actions';
import wegotyou from '../../../assets/images/wegotyou.png';

// header component
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';

// top menu item get
import {useAsync} from 'react-async';
import {getTopMenuList} from './TopMenu/TopMenuList';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1
    },
    headerAvtar: {
        ...theme.typography.commonAvatar,
        ...theme.typography.mediumAvatar,
        transition: 'all .2s ease-in-out',
        background: '#fff',
        color: '#000',
        '&:hover': {
            background: '#000',
            color: '#fff'
        }
    },
    boxContainer: {
        width: '250px',
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            width: 'auto'
        }
    },
    menuContainer: {
        padding: '0 22px',
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            width: 'auto'
        }
    },
    cartButton: {
        position: 'absolute',
        right: 150,
        top: 7
    },
    link: {
        color: '#fff !important',
        textDecoration: 'none',
        paddingRight: '35px',
        '&:hover': {
            color: '#80298F !important'
        }
    },
    wegotyou: {
        background: 'none',
        border: 'none'
    }
}));

const Header = (props) => {
    const {handleLeftDrawerToggle} = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const logOut = useRef();
    let topMenuItem = [];

    // 메뉴 list 조회
    const {
        data: detail,
        error,
        isLoading
    } = useAsync({
        promiseFn: getTopMenuList
    });

    if (isLoading) {
    }
    if (error || !detail) {
    }
    if (detail) {
        detail.forEach((item) => {
            topMenuItem.push(item);
        });
    }

    // 메뉴 이동
    const matchesSM = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const menuHandler = (id) => {
        dispatch({type: actionTypes.MENU_OPEN, isOpen: id});
        matchesSM && dispatch({type: actionTypes.SET_MENU, opened: false});
    };

    //logout
    const userLogout = () => {
        dispatch({ type: "SET_USER", userData: [] });
        sessionStorage.clear();
        logOut.current.click();
    };
    const [toLogOut, setToLogOut] = useState('');
    useEffect(() => {
        if (sessionStorage.getItem('userCompanyData') === 'CA10') {
            setToLogOut('/pages/login/');
        } else if (sessionStorage.getItem('userCompanyData') === 'AU10') {
            setToLogOut('/AU/login/');
        }

        return () => {};
    }, [toLogOut]);

    return (
        <React.Fragment>
            <div className={classes.boxContainer}>
                <Hidden mdDown>
                    <LogoSection />
                    <div className={classes.grow} />
                </Hidden>
                <button className={classes.wegotyou} onClick={handleLeftDrawerToggle}>
                    <img src={wegotyou} alt="LeftMenuButton" width="70" height="25" />
                </button>
            </div>

            <div className={classes.menuContainer}>
                {topMenuItem.map((item, i) => (
                    <Link className={classes.link} key={item.id} to={item.url} onClick={() => menuHandler(item.id)}>
                        {item.title}
                    </Link>
                ))}
            </div>
            <div className={classes.grow} />
            <ProfileSection />
            <div className="d-flex justify-content-end ms-3">
                <button type="button" className="btn btn-outline-secondary" onClick={userLogout}>
                    <IconLogout />
                    <Typography variant="h6" component={Link} ref={logOut} to={toLogOut}>
                        Logout
                    </Typography>
                </button>
            </div>
        </React.Fragment>
    );
};

export default Header;
