import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from "react-dom/client";
import {BrowserRouter} from 'react-router-dom';

import {legacy_createStore} from 'redux';
import {Provider} from 'react-redux';

import App from './App';
import reducer from './store/reducer';
import config from './config';
import './assets/scss/style.scss';
import './assets/scss/popupstyle.scss';
import * as serviceWorker from './serviceWorker';

import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

const store = legacy_createStore(reducer);
const persistor = persistStore(store);

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter basename={config.basename}>
                <App />
            </BrowserRouter>
        </PersistGate>
    </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
