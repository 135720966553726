import axios from 'axios';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {IconSquareRotated, IconPlayerPlay, IconHome} from '@tabler/icons';

const icons = {
    IconSquareRotated: IconSquareRotated,
    IconPlayerPlay: IconPlayerPlay,
    IconHome: IconHome
};

export const getLeftMenuList = async ({}) => {
    const url = '/api/menu';
    const res = await axios
        .patch(process.env.REACT_APP_REST_SERVER, {
            p_language: sessionStorage.getItem('userLanguage'),
            p_company: sessionStorage.getItem('userCompanyData'),
            p_user: sessionStorage.getItem('userID'),
            p_userip: '1.1.1.1',
            p_pgm: 'SP_DP_COM_LEFTMENU',
            p_option: {}
        })
        .catch((e) => console.log(e));
    const result = res.data.rows;

    const menuItems = {
        items: [
            {
                title: <FormattedMessage id="dealerpotal" />,
                type: 'group',
                children: [
                    {
                        id: 'home',
                        title: <FormattedMessage id="home" />,
                        type: 'item',
                        url: '/dashboard/default',
                        icon: icons['IconHome'],
                        breadcrumbs: false
                    }
                ]
            }
        ]
    };
    const groupList = result.filter((group) => group.MENU_TYPE === 'group');
    const collapseList = result.filter((collapse) => collapse.MENU_TYPE === 'collapse');
    const itemList = result.filter((item) => item.MENU_TYPE === 'item');
    groupList.forEach((group) => {
        const obj = {
            id: group.MENU_ID,
            title: group.MENU_NAME,
            type: 'group',
            children: []
        };
        // console.log('groupListobj', obj);
        // 메뉴 리스트에 0레벨 메뉴 삽입
        menuItems.items.push(obj);
    });

    collapseList.forEach((collapse) => {
        const obj = {
            id: collapse.MENU_ID,
            title: collapse.MENU_NAME,
            type: 'collapse',
            icon: icons[collapse.ICON_NAME],
            background: '2',
            children: []
        };
        // console.log('collapseListobj', obj);
        /**
         * 1. 전체 리스트에서 1레벨 메뉴의 parentId를 통해 0레벨 메뉴의 INDEX 찾기
         * 2. 전체 리스트에서 0레벨 메뉴의 Children에 1레벨 메뉴 삽입
         */
        const menu_groupIndex = menuItems.items.findIndex((x) => x.id === collapse.PARENT_ID);
        menuItems.items[menu_groupIndex].children.push(obj);
    });

    itemList.forEach((item) => {
        const obj = {
            id: item.MENU_ID,
            title: item.MENU_NAME,
            type: 'item',
            icon: icons[item.ICON_NAME],
            url: item.LINK_URL
        };
        // console.log('itemListobj', obj);
        /**
         * 1. item의 parentId를 이용하여 collapse 리스트에서 메뉴 INDEX 찾기
         * 2. collapse 리스트에서 찾은 메뉴 INDEX를 이용하여 parentId 찾기 (0레벨 menuId)
         * 3. 전체 리스트에서 2번에서 찾은 parentId로 INDEX 찾기
         * 4. 전체 리스트에서 3번에서 찾은 INDEX의 group 메뉴 가져오기
         * 5. 4번에서 가져온 group 메뉴에서
         */
        const collapseIndex = collapseList.findIndex((x) => x.MENU_ID === item.PARENT_ID);
        const collapse = collapseList[collapseIndex];
        const menu_groupIndex = menuItems.items.findIndex((x) => x.id === collapse.PARENT_ID);
        const menu_collapseList = menuItems.items[menu_groupIndex].children;
        const menu_collapseIndex = menu_collapseList.findIndex((x) => x.id === collapse.MENU_ID);
        menuItems.items[menu_groupIndex].children[menu_collapseIndex].children.push(obj);
    });
    return menuItems;
};
