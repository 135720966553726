import React, {Suspense, useEffect} from 'react';
import {Redirect, Switch, useLocation, useHistory} from 'react-router-dom';
import {AnimatePresence} from 'framer-motion';
import {useSelector} from 'react-redux';

import config from './../config';
import MainRoutes from './MainRoutes';

import Loader from '../ui-component/extended/Loader/Loader';

import AuthenticationRoutes from './AuthenticationRoutes';

const Routes = () => {
    // react router
    const location = useLocation();
    const history = useHistory();
    const whiteList = require('./whiteList.json');

    useEffect(() => {
        // login id 없을 경우 login으로 이동
        var whiteIndex = whiteList.findIndex(x => x.pathname === location.pathname)
        if (isEmpty(id) && whiteIndex !== -1) {
            history.push(config.defaultPath);
        } else {
            return () => {};
        }
    }, [location.pathname]);

    // login id
    const paraMeter = useSelector((state) => state.parameter);
    const id = paraMeter.userid;

    const isEmpty = (value) => {
        if (
            value == '' ||
            value == null ||
            value == undefined ||
            (value != null && typeof value == 'object' && !Object.keys(value).length)
        ) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <AnimatePresence>
            <Suspense fallback={<Loader />}>
                <Switch>
                    <Redirect exact from="/" to={config.defaultPath} />
                    <>
                        {/* Routes for authetication pages */}
                        <AuthenticationRoutes />

                        {/* Routes for main layouts */}
                        <MainRoutes />
                    </>
                </Switch>
            </Suspense>
        </AnimatePresence>
    );
};

export default Routes;
