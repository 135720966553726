import React, {lazy} from 'react';
import {Route, Switch, useLocation} from 'react-router-dom';

import MainLayout from './../layout/MainLayout';

const DashboardDefault = lazy(() => import('../views/dashboard/Default')); //notice

//MyCart
const cartOrder = lazy(() => import('../views/Cart/cartOrder'));
const cartMyCart = lazy(() => import('../views/Cart/cartMyCart'));
const cartPurchase = lazy(() => import('../views/Cart/cartPurchase'));

//MasterData
const mstCustomer = lazy(() => import('../views/MasterData/mstCustomer'));
const mstShipto = lazy(() => import('../views/MasterData/mstShipto'));
const mstProduct = lazy(() => import('../views/MasterData/mstProduct'));
const mstMailingList = lazy(() => import('../views/MasterData/mstMailingList'));
//----------

//OrderSheet
const ordVendorExcel = lazy(() => import('../views/OrderSheet/ordVendorExcel'));
const ordVendorTransfer = lazy(() => import('../views/OrderSheet/ordVendorTransfer'));
const ordActiveDealerList = lazy(() => import('../views/OrderSheet/ordActiveDealerList'));
const ordVendorOrderList = lazy(() => import('../views/OrderSheet/ordVendorOrderList'));
//----------

//SiteManagement
const sysMailTemplate = lazy(() => import('../views/SiteManagement/sysMailTemplate'));
//----------

//user
const UserUserList = lazy(() => import('../views/Usermanagement/userUserList'));

// const ApiTest = lazy(() => import('../views/youngsung/ApiTest'));
// const PopRegister = lazy(() => import('../views/youngsung/PopRegister'));
// const TwoTable = lazy(() => import('../views/youngsung/TwoTable'));
// const Grape = lazy(() => import('../views/youngsung/Grape'));

const Single = lazy(() => import('../views/template/Single'));
const Horizon = lazy(() => import('../views/template/Horizon'));
const Vertical = lazy(() => import('../views/template/Vertical'));
const Detail = lazy(() => import('../views/template/Detail'));

const MainRoutes = () => {
    const location = useLocation();

    return (
        <Route
            path={[
                '/dashboard/default', //notice

                //Cart
                '/Cart/cartOrder',
                '/Cart/cartMyCart',
                '/Cart/cartPurchase',

                //MasterData
                '/MasterData/mstCustomer',
                '/MasterData/mstShipto',
                '/MasterData/mstProduct',
                '/MasterData/mstMailingList',
                //----------

                //OrderSheet
                '/OrderSheet/ordVendorExcel',
                '/OrderSheet/ordVendorTransfer',
                '/OrderSheet/ordActiveDealerList',
                '/OrderSheet/ordVendorOrderList',
                //----------

                //SiteManagement
                '/SiteManagement/sysMailTemplate',
                //------------

                //user
                '/UserAccountManagement/userList',
                //------------
                '/youngsung/api-test',
                '/youngsung/pop-register',
                '/youngsung/two-table',
                '/youngsung/grape',

                '/template/single',
                '/template/horizon',
                '/template/vertical',
                '/template/detail'
            ]}
        >
            <MainLayout showBreadcrumb={true}>
                <Switch location={location} key={location.pathname}>
                    <Route path="/dashboard/default" component={DashboardDefault} />

                    {/* MyCart */}
                    <Route path="/Cart/cartOrder" component={cartOrder} />
                    <Route path="/Cart/cartMyCart" component={cartMyCart} />
                    <Route path="/Cart/cartPurchase" component={cartPurchase} />

                    {/* MasterData */}
                    <Route path="/MasterData/mstCustomer" component={mstCustomer} />
                    <Route path="/MasterData/mstShipto" component={mstShipto} />
                    <Route path="/MasterData/mstProduct" component={mstProduct} />
                    <Route path="/MasterData/mstMailingList" component={mstMailingList} />
                    {/* ---------- */}

                    {/* OrderSheet */}
                    <Route path="/OrderSheet/ordVendorExcel" component={ordVendorExcel} />
                    <Route path="/OrderSheet/ordVendorTransfer" component={ordVendorTransfer} />
                    <Route path="/OrderSheet/ordActiveDealerList" component={ordActiveDealerList} />
                    <Route path="/OrderSheet/ordVendorOrderList" component={ordVendorOrderList} />
                    {/* ---------- */}

                    {/* SiteManagement */}
                    <Route path="/SiteManagement/sysMailTemplate" component={sysMailTemplate} />
                    {/* ---------- */}

                    {/* user */}
                    <Route path="/UserAccountManagement/userList" component={UserUserList} />
                    {/* ---------- */}

                    {/* <Route path="/youngsung/api-test" component={ApiTest} /> */}
                    {/* <Route path="/youngsung/pop-register" component={PopRegister} /> */}
                    {/* <Route path="/youngsung/two-table" component={TwoTable} /> */}
                    {/* <Route path="/youngsung/grape" component={Grape} /> */}

                    <Route path="/template/single" component={Single} />
                    <Route path="/template/horizon" component={Horizon} />
                    <Route path="/template/vertical" component={Vertical} />
                    <Route path="/template/detail" component={Detail} />
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
