import {createMuiTheme} from '@material-ui/core/styles';
import value from '../assets/scss/_themes-vars.module.scss';
import {componentStyleOverrides} from './compStyleOverride';
import {themePalatte} from './palatte';
import {themeTypography} from './typography';

export function theme(customization) {
    let navObject = {
        paper: '',
        backgroundDefault: '',
        background: '',
        textPrimary: '',
        textSecondary: '',
        textDark: '',
        textWhite: '',
        menuSelected: '',
        menuSelectedBack: '',
        divider: '',
        blue25: '',
        blue500: '',
        deepPurple900: '',
        searchCard: '',
        customization: customization
    };

    switch (customization.navType) {
        case 'light':
        default: // siedmenu color text color
            navObject.paper = value.paper;
            navObject.backgroundDefault = value.grey300;
            navObject.background = value.grey300;
            navObject.textPrimary = value.grey800;
            navObject.textSecondary = value.grey500;
            navObject.textDark = value.grey900;
            navObject.textWhite = value.paper;
            navObject.menuSelected = value.deepPurple600;
            navObject.menuSelectedBack = value.blue50;
            navObject.divider = value.grey300;
            navObject.blue25 = value.blue25;
            navObject.blue500 = value.blue500;
            navObject.deepPurple900 = value.deepPurple900;
            break;
    }

    return createMuiTheme({
        direction: customization.rtlLayout ? 'rtl' : 'ltr',
        palette: themePalatte(navObject),
        mixins: {
            toolbar: {
                minHeight: '48px',
                padding: '0px', // 16px
                '@media (min-width: 600px)': {
                    minHeight: '48px'
                }
            }
        },
        typography: themeTypography(navObject),
        components: componentStyleOverrides(navObject)
    });
}

export default theme;
