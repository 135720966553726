import "@grapecity/wijmo.styles/wijmo.css";
import "bootstrap/dist/css/bootstrap.css";
import "@grapecity/wijmo.react.grid";
import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";

const ComBtn = ({ pOnClick, pClassName, pName, pBtnName, pRef, pOnKeyPress, pTextType, pDisabled  }) => {


  return (
      <button 
        name={pName} 
        className={pClassName} 
        onClick={pOnClick} 
        ref={pRef} 
        onKeyPress={pOnKeyPress} 
        disabled={pDisabled} 
      >
        <Typography variant={pTextType === undefined ? 'h6' : pTextType}>{pBtnName}</Typography>
      </button>
  );
};

export { ComBtn };
