import React from 'react';
import {Card, CardContent, CardHeader, Divider, Typography} from '@material-ui/core';

const PopupCard = ({title, children, darkTitle, contentClass, secondary, cardClass}) => {
    return (
        <Card
            sx={{
                border: '1px solid',
                // borderColor: theme.palette.primary.light,
                background: '#fff',
                ':hover': {
                    boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%)'
                }
            }}
            className={cardClass}
        >
            {!darkTitle && title && <CardHeader title={<Typography variant="h5">{title}</Typography>} action={secondary} />}
            {darkTitle && title && <CardHeader title={<Typography variant="h4">{title}</Typography>} />}
            {title && <Divider sx={{borderColor: 'primary.light'}} />}
            <CardContent className={contentClass}>{children}</CardContent>
        </Card>
    );
};

export default PopupCard;
