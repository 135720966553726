import {SET_ACCOUNT_RTNRESULT, ACCOUNT_INITIALISE, LOGIN, LOGOUT} from './actions';

export const initialState = {
    accountRtnResult:[],
};

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACCOUNT_RTNRESULT:
            return {
                ...state, 
                accountRtnResult: action.accountRtnResult
            };
        case ACCOUNT_INITIALISE: {
            const {isLoggedIn, user} = action.payload;
            return {
                ...state,
                isLoggedIn,
                isInitialised: true,
                user
            };
        }
        case LOGIN: {
            const {user} = action.payload;
            return {
                ...state,
                isLoggedIn: true,
                user
            };
        }
        case LOGOUT: {
            return {
                ...state,
                isLoggedIn: false,
                user: null
            };
        }
        default: {
            return {...state};
        }
    }
};

export default accountReducer;
