import React from 'react';
import {useAsync} from 'react-async';
import {Typography} from '@material-ui/core';

import NavGroup from './NavGroup';
import menuItem from './../../../../menu-items/main-menu-items';
import {getLeftMenuList} from './LeftMenuList';

const MenuList = () => {
    const {
        data: detail,
        error,
        isLoading
    } = useAsync({
        promiseFn: getLeftMenuList
    });

    if (isLoading) {
    }
    if (error || !detail) {
    }
    if (detail) {
        menuItem.items = detail.items;
    }
    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id || ''} item={item} />;
            default:
                return (
                    <Typography key={item.id || ''} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return navItems;
};

export default MenuList;
